import React from "react"
import { createStore, applyMiddleware, compose } from "redux"
import { Provider } from "react-redux"
import thunkMiddleware from "redux-thunk"
import rootReducer from "./reducer"
import ToastProvider from "../components/reuse/toast/ToastProvider"

const setupStore = () => {
    const composeEnhancers = windowExists() && reduxDevToolsExist() ? getReduxDevTools() : compose

    return createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))
}

export default ({ element }) => (
    <Provider store={setupStore()}>
        <ToastProvider variant={"bottom_left"} cookieConsent={undefined}>
            {element}
        </ToastProvider>
    </Provider>
)

const windowExists = () => typeof window === "object"
const getReduxDevTools = () => window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
const reduxDevToolsExist = () => typeof getReduxDevTools() === "function"
