import React from "react"
import PropTypes from "prop-types"
import * as HeroIcons from '@heroicons/react/24/outline'

const Icon = props => {
    return (
        <span className={props.className}>
            {props.children ||
                (props.icon ? (
                    React.createElement(HeroIcons[toPascalCase(props.icon) + "Icon"], {
                        style: { maxWidth: props.maxWidth || "2.5rem" },
                        className: props.className,
                    })
                ) : (
                    <></>
                ))}
        </span>
    )
}

Icon.propTypes = {
    maxWidth: PropTypes.number,
    className: PropTypes.string,
    icon: PropTypes.string,
}

const toPascalCase = text => {
    return text.replace(/(^\w|-\w)/g, text => text.replace(/-/, "").toUpperCase())
}

export default Icon
