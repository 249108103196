import fetch from "cross-fetch"

import { backendUrl } from "../../config"
import { reportError } from "../../components/reuse/util/eventReporter"

export const FETCHING_NEWS = "FETCHING_NEWS"
export const RECEIVED_NEWS = "RECEIVED_NEWS"

export const fetchNews = () => {
    return dispatch => {
        dispatch({ type: FETCHING_NEWS })
        return fetch(backendUrl + "/news-entries")
            .then(res => {
                if (res.status !== 200) {
                    reportError(FETCHING_NEWS, `${res.status}`)
                    throw new Error("Fehler beim Laden von News")
                }
                return res.json()
            })
            .then(data => dispatch(receivedNews(data)))
            .catch(err => dispatch(receivedNews(undefined, err)))
    }
}

const receivedNews = (data, error) => {
    return {
        type: RECEIVED_NEWS,
        result: {
            data: data,
            error: error,
        },
    }
}
