import fetch from "cross-fetch"

import { backendUrl } from "../../config"
import { reportError } from "../../components/reuse/util/eventReporter"

export const FETCHING_IMPRESSUM = "FETCHING_IMPRESSUM"
export const RECEIVED_IMPRESSUM = "RECEIVED_IMPRESSUM"

export const fetchImpressum = () => {
    return dispatch => {
        dispatch({ type: FETCHING_IMPRESSUM })
        return fetch(backendUrl + "/impressum")
            .then(res => {
                if (res.status !== 200) {
                    reportError(FETCHING_IMPRESSUM, `${res.status}`)
                    throw new Error("Fehler beim Laden von Impressum")
                }
                return res.json()
            })
            .then(data => dispatch(receivedImpressum(data)))
            .catch(err => dispatch(receivedImpressum(undefined, err)))
    }
}

const receivedImpressum = (data, error) => {
    return {
        type: RECEIVED_IMPRESSUM,
        result: {
            data: data,
            error: error,
        },
    }
}
