import React, { useCallback, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Cookies from "js-cookie"

import ToastContainer from "./ToastContainer"

const ToastProvider = props => {
    const [data, setData] = useState([])
    const Push = useCallback(
        (message, type, lifetime, truncate) => {
            if (message) {
                const new_item = {
                    id: uuidv4(),
                    message: message,
                    type: type,
                    lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
                    truncate: truncate,
                }
                setData(prevState => [...prevState, new_item])
            }
        },
        [setData]
    )

    useEffect(() => {
        if (typeof props.cookieConsent === "string" && Cookies.get(props.cookieConsent) === undefined) {
            const exists = data.findIndex(element => (element.id = "cookie-consent")) !== -1
            if (!exists) {
                const cookieConsentBanner = {
                    id: "cookie-consent",
                    type: "Cookie",
                    lifetime: -1,
                    onAccept: () =>
                        window._paq?.push(["rememberConsentGiven"]) && window._paq?.push(["enableJSErrorTracking"]),
                }
                setTimeout(() => setData(prevState => [...prevState, cookieConsentBanner]), 1000)
            }
        }
    }, [props.cookieConsent])

    const PushError = useCallback((message, lifetime, truncate) => Push(message, "Error", lifetime, truncate), [Push])
    const PushWarning = useCallback((message, lifetime, truncate) => Push(message, "Warning", lifetime, truncate), [
        Push,
    ])
    const PushSuccess = useCallback((message, lifetime, truncate) => Push(message, "Success", lifetime, truncate), [
        Push,
    ])
    const PushInfo = useCallback((message, lifetime, truncate) => Push(message, "Info", lifetime, truncate), [Push])

    const ToastContexd = useCallback(() => {
        return {
            data: data,
            pushError: PushError,
            pushWarning: PushWarning,
            pushSuccess: PushSuccess,
            pushInfo: PushInfo,
            push: Push,
            async remove(id) {
                setData(prevState => prevState.filter(e => e.id !== id))
            },
        }
    }, [data, setData, PushError, PushWarning, PushSuccess, PushInfo, Push])

    return (
        <ToastContext.Provider value={ToastContexd()}>
            <ToastContainer variant={props.variant} />
            {props.children}
        </ToastContext.Provider>
    )
}

export default ToastProvider

export const ToastContext = React.createContext(undefined)

export const useToast = () => useContext(ToastContext)

export const useToastMessageOnError = error => {
    let toastContext = useToast()
    return useEffect(() => {
        error && toastContext && toastContext.pushError(error.message || "Es ist ein Fehler aufgetreten")
    }, [error])
}

ToastProvider.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.string,
}

const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

const DEFAULT_INTERVAL = 3500
