import fetch from "cross-fetch"

import { backendUrl } from "../../config"
import { reportError } from "../../components/reuse/util/eventReporter"

export const FETCHING_LANDINGPAGE = "FETCHING_LANDINGPAGE"
export const RECEIVED_LANDINGPAGE = "RECEIVED_LANDINGPAGE"

export const fetchLandingpage = () => {
    return dispatch => {
        dispatch({ type: FETCHING_LANDINGPAGE })
        return fetch(backendUrl + "/landingpage")
            .then(res => {
                if (res.status !== 200) {
                    reportError(FETCHING_LANDINGPAGE, `${res.status}`)
                    throw new Error("Fehler beim Laden von Landingpage")
                }
                return res.json()
            })
            .then(data => dispatch(receivedLandingpage(data)))
            .catch(err => dispatch(receivedLandingpage(undefined, err)))
    }
}

const receivedLandingpage = (data, error) => {
    return {
        type: RECEIVED_LANDINGPAGE,
        result: {
            data: data,
            error: error,
        },
    }
}
