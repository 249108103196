import fetch from "cross-fetch"
import gatsbyConfig from "../../../../gatsby-config"

export const reportError = (action, name) => {
    fetch(buildEventUrl("error", action, name))
}

export const reportInteraction = (action, name) => {
    if (window && window._paq) {
        window._paq.push(["trackEvent", "Interaktionen", action, name])
    } else {
        fetch(buildEventUrl("Interaktion", action, name))
    }
}

const buildEventUrl = (c, action, name) => {
    let matomoOptions = gatsbyConfig.plugins.find(plugin => plugin.resolve === "gatsby-plugin-matomo")?.options
    return `${matomoOptions?.matomoUrl}/matomo.php?idsite=${
        matomoOptions?.siteId
    }&rec=1&rand=${randomEventId()}&e_c=${encodeURIComponent(c)}&e_a=${encodeURIComponent(
        action
    )}&e_n=${encodeURIComponent(name)}`
}

const randomEventId = () => {
    return "xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}
