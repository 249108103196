import { FETCHING_LANDINGPAGE, RECEIVED_LANDINGPAGE } from "../action/landingpage"

const initialState = {
    data: undefined,
    loading: false,
    loaded: false,
    error: undefined,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_LANDINGPAGE:
            return Object.assign({}, state, {
                loading: true,
            })
        case RECEIVED_LANDINGPAGE:
            return Object.assign({}, state, {
                loading: false,
                loaded: true,
                error: action.result.error,
                data: action.result.data,
            })
        default:
            return state
    }
}
