import React, { useContext } from "react"
import PropTypes from "prop-types"
import ToastMessage from "./ToastMessage"
import { ToastContext } from "./ToastProvider"

const VARIANTS = {
    top_left: {
        style: "top-0 left-0",
    },
    top_right: {
        style: "top-0 right-0",
    },
    bottom_right: {
        style: "md:bottom-0 bottom-10 right-0",
    },
    bottom_left: {
        style: "md:bottom-0 bottom-10 left-0",
    },
    top_middle: {
        style: "top-0 left-1/2 -translate-x-1/2 transform",
    },
    bottom_middle: {
        style: "bottom-0 left-1/2 -translate-x-1/2 transform",
    },
    undefined: {
        style: "top-0 right-0",
    },
}

const ToastContainer = props => {
    const context = useContext(ToastContext)
    const variant = VARIANTS[props.variant] || VARIANTS.bottom_right
    const handleRemove = id => context && context.remove(id)

    return (
        <div
            className={
                variant.style +
                " fixed z-50 w-full md:max-w-md p-4 md:p-4 max-h-screen overflow-hidden pointer-events-none"
            }
        >
            <div className="flex-1 flex-col fade w-full mr-8 justify-end pointer-events-none">
                {context &&
                    context.data.map(toast => {
                        return (
                            <div
                                key={toast.id}
                                className="flex py-1 w-full transform transition-all duration-300 pointer-events-auto"
                            >
                                <ToastMessage
                                    id={toast.id}
                                    message={toast.message}
                                    type={toast.type}
                                    header={toast.header}
                                    icon={toast.icon}
                                    truncate={toast.truncate}
                                    onRemove={handleRemove}
                                    onAccept={toast.onAccept}
                                    lifetime={toast.lifetime}
                                />
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default ToastContainer

ToastContainer.propTypes = {
    variant: PropTypes.string,
}
