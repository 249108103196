import { FETCHING_IMPRESSUM, RECEIVED_IMPRESSUM } from "../action/impressum"

const initialState = {
    data: undefined,
    loading: false,
    loaded: false,
    error: undefined,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_IMPRESSUM:
            return Object.assign({}, state, {
                loading: true,
            })
        case RECEIVED_IMPRESSUM:
            return Object.assign({}, state, {
                loading: false,
                loaded: true,
                error: action.result.error,
                data: action.result.data,
            })
        default:
            return state
    }
}
