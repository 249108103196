import { Link } from "gatsby"
import React, { useEffect } from "react"
import Icon from "../base/icon"

const VARIANTS = {
    Info: {
        base: "bg-white border-moos",
        iconstyle: "text-moos ",
        icon: "information-circle",
        name: "Info",
    },

    Error: {
        base: "bg-white border-red-500 ",
        iconstyle: "text-red-500 ",
        icon: "exclamation-circle",
        name: "Fehler",
    },

    Warning: {
        base: "bg-white border-yellow-500",
        iconstyle: "text-yellow-500 ",
        icon: "exclamation",
        name: "Warnung",
    },

    Success: {
        base: "bg-white border-green-500",
        iconstyle: "text-green-500 ",
        icon: "check-circle",
        name: "Erfolgreich",
    },

    Cookie: {
        base: "bg-white border-moos",
        iconstyle: "text-moos ",
        icon: "information-circle",
        name: "Wir benötigen Ihre Zustimmung",
    },
}

export const Truncate = {
    1: "truncate-1-lines",
    2: "truncate-2-lines",
    3: "truncate-3-lines",
}

export default ({ id, header, message, lifetime, onRemove, truncate, icon, type, onAccept }) => {
    const RegularToast = () => (
        <div className="flex flex-row p-2 flex-no-wrap w-full items-center">
            {variant.icon && (
                <div className="flex items-center mx-auto text-xl select-none">
                    <Icon className={"h-12 w-12 mx-auto " + variant.iconstyle} icon={variant.icon} maxWidth="3rem" />
                </div>
            )}

            <div className="flex flex-col flex-no-wrap mx-4 w-full">
                <div className={"flex my-auto font-bold select-none pb-4 pt-2 text-sm uppercase " + variant.iconstyle}>
                    {variant.name}
                </div>
                <p className={"-mt-0.5 my-auto break-all flex text-gray-600 text-sm pb-2 " + truncate}>{message}</p>
            </div>
            <button
                onClick={() => onRemove && onRemove(id)}
                className="w-10 h-12 mr-2 flex items-center mx-auto text-center leading-none text-sm"
            >
                <Icon maxWidth="3rem">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="mx-auto my-auto w-full text-center text-gray-600 cursor-pointer hover:scale-125 transform transition-transform	"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </Icon>
            </button>
        </div>
    )

    const CookieToast = () => (
        <div className="flex flex-row p-2 flex-no-wrap w-full items-center">
            {variant.icon && (
                <div className="flex items-center mx-auto text-xl select-none">
                    <Icon className={"h-12 w-12 mx-auto " + variant.iconstyle} icon={variant.icon} />
                </div>
            )}

            <div className="flex flex-col flex-no-wrap mx-4 w-full">
                <div className={"flex my-auto font-bold select-none pb-4 pt-2 text-xs uppercase " + variant.iconstyle}>
                    {variant.name}
                </div>
                <p className={"-mt-0.5 my-auto text-gray-600 text-xs pb-2 break-normal"}>
                    <span>Zur Verbesserung der Benutzererfahrung möchte diese Seite Cookies verwenden. </span>
                    <Link to="/datenschutz" className="text-xs transform scale-50 text-primary">
                        Datenschutz
                    </Link>
                </p>
            </div>
            <button
                onClick={() => {
                    onRemove && onRemove(id)
                    onAccept && onAccept()
                }}
                className="w-10 h-12 mr-2 flex items-center mx-auto text-center leading-none text-sm"
            >
                <Icon
                    className="mx-auto my-auto w-full text-center text-primary cursor-pointer hover:scale-125 transform transition-transform"
                    icon="check"
                />
            </button>
        </div>
    )

    const variant = type
        ? VARIANTS[type]
        : {
              base: "bg-white border-gray-600 ",
              iconstyle: "",
              icon: icon,
              name: header,
          }

    useEffect(() => {
        if (lifetime && lifetime !== -1 && onRemove) {
            setTimeout(() => {
                onRemove(id)
            }, lifetime)
        }
    }, [lifetime, id, onRemove])

    return (
        <div
            className={
                "flex w-full visible flex-row shadow-lg md:shadow-xl rounded-md duration-100 cursor-pointer transform transition-all hover:scale-105 " +
                variant.base +
                (type ? " max-h-60" : "")
            }
        >
            {type === "Cookie" ? <CookieToast /> : <RegularToast />}
        </div>
    )
}
