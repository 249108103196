module.exports = {
    siteMetadata: {
        title: `Lars Maximilian Schweizer`,
        description: `Lars Maximilian Schweizer ist Bundestagskandidat für die Wahl am 26. September 2021. Er tritt für Bündnis 90/Die Grünen im Wahlkreis Neckar-Zaber an`,
    },
    trailingSlash: "never",
    plugins: [
        "gatsby-plugin-postcss",
        "gatsby-plugin-react-helmet",
        {
          resolve: 'gatsby-plugin-manifest',
          options: {
            icon: "src/images/icon.png",
            icons: [
                {
                    src: "favicons/icon-48x48.png",
                    sizes: "48x48",
                    type: "image/png",
                },
                {
                    src: "favicons/icon-72x72.png",
                    sizes: "72x72",
                    type: "image/png",
                },
                {
                    src: "favicons/icon-96x96.png",
                    sizes: "96x96",
                    type: "image/png",
                },
                {
                    src: "favicons/icon-144x144.png",
                    sizes: "144x144",
                    type: "image/png",
                },
                {
                    src: "favicons/icon-192x192.png",
                    sizes: "192x192",
                    type: "image/png",
                },
            ],
            name: "Lars Maximilian Schweizer",
            short_name: "Lars Maximilian Schweizer",
            start_url: `/`,
            theme_color: `#145F32`,
          }
        },
        {
          resolve: 'gatsby-source-filesystem',
          options: {
            "name": "images",
            "path": "./src/images/"
          },
          __key: "images"
        },
        {
            resolve: `gatsby-source-license`,
            options: {
                depth: 2,
                excludeSelf: true,
                excludedDependencies: [],
                includeDependencies: true,
                includeDevDependencies: false,
            },
        },
      ]
}
